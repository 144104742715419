<template>
  <div class="merch-box">
    <div class="update">
      <div class="title">商品说明书</div>
      <div v-if="showUpdateExplain" @click="openUp">
        <span>更新商品说明书</span>
      </div>
    </div>
    <div>
      <div class="listData" v-for="(item, index) in instruction" :key="index">
        <div class="listTitle" :class="index == 0 ? 'top' : ''">
          {{ item.name }}
        </div>
        <div
          class="listContent"
          :class="index == 0 ? 'top' : ''"
          v-if="instDate"
        >
          {{ instDate[item.key] || "/" }}
        </div>
      </div>
    </div>
    <div>
      <!-- <pre><Editor v-model="form" class="Editor" /></pre> -->
    </div>
    <el-dialog
      title="商品说明书更新提醒"
      :visible.sync="goodsDialogVisible"
      width="500px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="closeUp()"
    >
      <span class="dialog-mess">
        确定更新商品说明书？若已填写商品说明书，会被覆盖替换。
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="updateExplain()" type="primary"> 确定 </el-button>
        <el-button @click="closeUp()"> 取消 </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const commonIndex = createNamespacedHelpers("commonIndex"); //vuex公共库
import Editor from "@/components/Editor/index.vue"; //商品说明书
export default {
  props: {
    instruction: {
      type: Array,
      default: () => [],
    }, //商品说明书
    instDate: {
      type: Object,
      default: () => {},
    }, //说明书数据
    showUpdateExplain: {
      type: Boolean,
    },
    isUpdate: {
      type: Boolean,
    },
    dialogVisibleSon: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Editor,
  },
  data() {
    return {
      dialogVisible: this.dialogVisibleSon,
    };
  },
  computed: {
    ...commonIndex.mapState(["goodsDialogVisible"]),
  },
  // watch:{
  //   dialogVisibleSon(val){
  //     console.log(val)
  //   }

  // },
  methods: {
    ...commonIndex.mapMutations(["ChangegoodsDialogVisible"]),
    openUp() {
      this.ChangegoodsDialogVisible(true);
    },
    closeUp() {
      this.ChangegoodsDialogVisible(false);
    },
    updateExplain() {
      this.$emit("updateExplain");
    },
  },
};
</script>

<style lang="scss" scoped>
.merch-box {
  padding: 10px;
  width: 100%;
  .title {
    width: 100%;
    font-weight: bold;
    padding-bottom: 0px;
    // border-bottom: dashed 1px #ddd;
    display: flex;
    align-items: center;
    // margin-bottom: 10px;
  }
  .title::before {
    width: 4px;
    height: 16px;
    background-color: #06b7ae;
    border-radius: 10px;
    display: inline-block;
    content: "";
    margin-right: 10px;
    margin-bottom: -2px;
  }
  .center-box {
    padding-left: 20px;
    margin-top: 10px;
    width: 100%;
    // border: 1px solid #797979;
    border-radius: 4px;
  }
}
.listData {
  display: flex;
  line-height: 100%;
  width: 100%;
  border-top: 0px;
  .listTitle {
    width: 250px;
    padding: 15px 10px;
    border: 1px solid #d4d7d9;
    border-top: 0px;
    background-color: #f7f8fa;
    color: #6d7687;
    font-size: 14px;
    text-align: center;
  }
  .listContent {
    flex: 1;
    padding: 15px 10px;
    border: 1px solid #d4d7d9;
    border-left: none;
    border-top: 0px;
    font-size: 14px;
    color: #2c384d;
  }
  .top {
    border-top: 1px solid #d4d7d9;
  }
}
.update {
  display: flex;
  justify-content: space-between;
  align-content: flex-end;
  border-bottom: dashed 1px #ddd;
  padding-bottom: 10px;
  margin-bottom: 10px;
  div {
    display: flex;
    align-items: center;
    // padding-bottom: 16px;
    span {
      width: 120px;
      display: inline-block;
      font-size: 14px;
      background-color: #06b7ae;
      color: #fff;
      text-align: center;
      height: 24px;
      line-height: 24px;
      border-radius: 4px;
      cursor: pointer;
    }
  }
}
::v-deep .el-dialog__body {
  padding: 10px 20px;
  line-height: 20px;
}
</style>
