<template>
  <div class="MERCH">
    <div class="merch-card" v-if="AuditbusinessData.auditinfo">
      <div class="message-box scorr-roll">
        <!-- 审核时间线 -->
        <!-- <div class="time-wire"></div> -->
        <!-- 未通过显示框 -->
        <template v-if="AuditbusinessData.auditinfo">
          <div
            v-if="
              AuditbusinessData.auditinfo.status == 2 ||
              AuditbusinessData.auditinfo.status == 3
            "
          >
            <NotPassGood :notData="AuditbusinessData" />
          </div>
        </template>

        <!-- 供应商 -->
        <div>
          <MerchantGood
            :businessInfo="goodsMessage.businessInfo"
            :businessInfoNew="AuditbusinessData.business"
          />
        </div>
        <!-- 基本信息 -->
        <div>
          <BasicMessageGood
            :basicInfo="goodsMessage.basicInfo"
            :picture="goodsMessage.picture"
            :manufacturerInfo="goodsMessage.manufacturerInfo"
            :label="goodsMessage.label"
            :basicinfoData="AuditbusinessData.basicinfo"
            :imgDate="AuditbusinessData.picture"
            :manuData="AuditbusinessData.manufacturerinfo"
            :labData="AuditbusinessData.label"
          />
        </div>
        <!-- 商品说明书 -->
        <div>
          <ExplainGood
            :instruction="goodsMessage.instruction"
            :instDate="AuditbusinessData.instruction"
          />
        </div>
        <!-- 价格/库存 -->
        <div>
          <PriceGood
            :priceAndInventory="goodsMessage.priceAndInventory"
            :priceData="AuditbusinessData.priceandinventory"
          />
        </div>
        <!-- 产品资质 -->
        <div>
          <QualificationGood
            :qualify="goodsMessage.qualify"
            :qualifyData="AuditbusinessData.qualify"
          />
        </div>
        <!-- 销售设置 -->
        <div>
          <SellSetGood
            :saleSetting="goodsMessage.saleSetting"
            :saleData="AuditbusinessData.salesetting"
          />
        </div>
      </div>
      <div
        class="btn-box"
        v-if="
          AuditbusinessData.auditinfo.status != 2 &&
          AuditbusinessData.auditinfo.status != 3
        "
      >
        <div class="pass" @click="passMessage">通过</div>
        <div class="refuse" @click="refuseMessage">拒绝</div>
      </div>
      <div class="btn-box" v-else>
        <div class="refuse" @click="toBack">返回</div>
      </div>
      <!-- 弹框 -->
      <div>
        <el-dialog title="不通过原因" :visible.sync="isRefuse" width="600px">
          <div>
            <el-input
              type="textarea"
              :rows="2"
              placeholder="请输入内容"
              v-model="AuditMessage.remark"
            >
            </el-input>
          </div>
          <span slot="footer" class="dialog-footer">
            <el-button @click="isRefuse = false">取 消</el-button>
            <el-button type="primary" @click="notarizeRefuse">确 定</el-button>
          </span>
        </el-dialog>
      </div>
      <div>
        <el-dialog title="确认" :visible.sync="ispass" width="600px">
          <div>相关资料信息是否均符合平台要求，请确认是否确认通过？</div>
          <span slot="footer" class="dialog-footer">
            <el-button @click="ispass = false">取 消</el-button>
            <el-button type="primary" @click="nextPass">确 定</el-button>
          </span>
        </el-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import MerchantGood from "@/components/ordinaryGoods/merchant.vue"; //供应商
import BasicMessageGood from "@/components/ordinaryGoods/basicMessage.vue"; //基本信息
import ExplainGood from "@/components/ordinaryGoods/explain.vue"; //商品说明书
import SellSetGood from "@/components/ordinaryGoods/sellSet.vue"; //销售设置
import PriceGood from "@/components/ordinaryGoods/price.vue"; //价格/库存
import QualificationGood from "@/components/ordinaryGoods/qualification.vue"; //产品资质
import NotPassGood from "../../audit/merchAudit/merchMessage/comp/notPass.vue"; //产品资质
import { createNamespacedHelpers } from "vuex";
const { mapState, mapActions } = createNamespacedHelpers("Goods"); //vuex公共库
export default {
  data() {
    return {
      activeName: 0,
      // 引入详情json
      goodsMessage: require("../../../assets/json/goodsAntd.json"),
      AuditMessage: {
        id: this.$route.query.id,
        remark: "",
        status: 2,
      },
      isRefuse: false, //孔氏拒绝弹框
      ispass: false, //通过拒绝类型
    };
  },
  components: {
    MerchantGood, //供应商
    BasicMessageGood, //基本信息
    ExplainGood, //商品说明书
    SellSetGood, //销售设置
    PriceGood, //价格/库存
    QualificationGood, //产品资质
    NotPassGood, //未通过
  },
  computed: {
    ...mapState(["AuditbusinessData"]),
  },
  created() {
    if (this.$route.query.id) {
      this.postgoodsAuditbusinessDetail({ id: this.$route.query.id });
    }
  },
  methods: {
    ...mapActions(["postgoodsAuditbusinessDetail", "postgoodsAuditaudit"]),
    //  返回
    toBack() {
      this.$router.push({ path: "/goodsCheck" });
    },
    // 拒绝弹框
    refuseMessage() {
      this.isRefuse = true;
    },
    // 通过弹框
    passMessage() {
      this.ispass = true;
    },
    // 通过
    async nextPass() {
      this.AuditMessage = {
        id: this.$route.query.id,
        status: 2,
      };
      let data = await this.postgoodsAuditaudit(this.AuditMessage);
      if (data.code == 200) {
        this.ispass = false;
        this.$router.push({ path: "/goodsCheck" });
        this.$message({
          message: data.message,
          type: "success",
        });
      } else {
        this.$message({
          message: data.message,
          type: "warning",
        });
      }
    },
    // 确认拒绝
    async notarizeRefuse() {
      this.AuditMessage.status = 3;
      let data = await this.postgoodsAuditaudit(this.AuditMessage);
      if (data.code == 200) {
        this.isRefuse = false;
        this.$router.push({ path: "/goodsCheck" });
        this.$message({
          message: data.message,
          type: "success",
        });
      } else {
        this.$message({
          message: data.message,
          type: "warning",
        });
      }
    },
    // 供应商审核
    goodAudit() {
      this.AuditMessage = {
        id: this.$route.query.id,
      };
    },
    handleCurrentChange() {},
    toSearch() {},
    // tag标签切换
    handleClick(tab, event) {
      //console.log(tab, event);
    },
    //
    toMessage() {
      this.$router.push({ path: "/cloudGoodMessage" });
    },
  },
};
</script>
<style lang="scss" scoped>
.dio-input {
  .el-input {
    width: 300px !important;
  }
  .el-upload--picture-card {
    width: 100px;
    height: 100px;
    line-height: 100px;
  }
}
.el-tabs__item.is-active {
  color: #06B7AE;
}
.el-tabs__item:hover {
  color: #06B7AE;
}
.el-tabs__active-bar {
  background-color: #06B7AE;
}
.el-input-group__prepend {
  padding: 0px 0px 0px 5px !important;
}
.el-input__inner {
  border-radius: 4px !important;
}
.el-range-separator {
  width: 20% !important;
}
</style>
<style lang="scss" scoped>
.userBtn {
  display: flex;
  justify-content: center;
  div {
    margin-left: 5px;
    color: #1abca9;
    cursor: pointer;
  }
}
.MERCH {
  width: 100%;
  height: 100%;
  .merch-card {
    width: 100%;
    height: 100%;
    padding: 20px;
    padding-top: 10px;
    border-radius: 4px;
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
    background: #ffffff;
    .merch-tag {
      width: 100%;
      height: 40px;
    }
    .message-box {
      width: 100%;
      max-height: 75vh;
      padding-top: 10px;
      .time-wire {
        width: 100%;
        height: 100px;
        background: rebeccapurple;
      }
    }
  }
  .btn-box {
    cursor: pointer;
    margin-top: 1%;
    width: 100%;
    height: 40px;
    display: flex;
    align-content: center;
    justify-content: center;
    .pass {
      margin-right: 1%;
      width: 100px;
      height: 100%;
      background: #00bfbf;
      text-align: center;
      line-height: 40px;
      color: #ffffff;
      font-size: 13px;
      font-weight: 700;
      border-radius: 4px;
    }
    .refuse {
      width: 100px;
      height: 100%;
      background: #d7d7d7;
      text-align: center;
      line-height: 40px;
      color: black;
      font-size: 13px;
      font-weight: 700;
      border-radius: 4px;
    }
  }
}
</style>