<template>
  <div class="merch-box">
    <div class="title">审核结果</div>
    <div class="center-box">
      <div style="display: flex; width: 25%">
        <div>审核状态</div>
        <div
          class="el-icon-error col_F34444 marle_40"
          v-if="notData.auditInfo.status == 3"
        ></div>
        <div v-if="notData.auditInfo.status == 3" class="marle_5">审核失败</div>
        <div
          class="el-icon-success col_3DBB2B marle_40"
          v-if="notData.auditInfo.status != 3"
        ></div>
        <div v-if="notData.auditInfo.status != 3" class="marle_5">审核通过</div>
      </div>
      <div
        v-if="notData.auditInfo.status == 2 || notData.auditInfo.status == 3"
        style="width: 30%"
      >
        审核人 <span class="marle_40">{{ notData.auditInfo.name || "/" }}</span>
      </div>
      <div
        v-if="notData.auditInfo.status == 2 || notData.auditInfo.status == 3"
        style="width: 30%"
      >
        审核时间
        <span class="marle_40">{{ notData.auditInfo.update_time || "/" }}</span>
      </div>
    </div>
    <div
      class="verifier-per"
      v-if="notData.auditInfo.status == 2 || notData.auditInfo.status == 3"
    >
      <div class="pass-text" v-if="notData.auditInfo.status == 3">
        <span v-if="notData.auditInfo.remark != ''">
          不通过原因<span class="marle_40">{{ notData.auditInfo.remark }}</span>
        </span>
        <span v-else>未填写拒绝原因</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    UserQualification: {
      type: Object,
      default: () => {},
    },
    notData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      textIf: true, // 展开收起隐藏
      textShow: false, // 展开收起切换
      textMax: 20, // 显示最多字数
      detailText: "", // 文字内容
    };
  },
  computed: {
    sliceStr() {
      if (this.detailText == "" || this.detailText == undefined) {
        return this.detailText; // 数据为空直接返回数据
      } else {
        if (
          this.detailText.length > this.textMax ||
          this.textMax == +Infinity
        ) {
          this.textIf = false;
          return this.detailText.length > this.textMax
            ? this.detailText.substring(0, this.textMax) + "..."
            : this.detailText.substring(0, this.textMax); //隐藏多余文字+"..."
        } else {
          this.textIf = true;
        }
      }
      return this.detailText;
    },
  },
  methods: {
    Text() {
      this.textShow = !this.textShow;
      this.textShow == false ? (this.textMax = 20) : (this.textMax = +Infinity);
    },
  },
};
</script>

<style lang="scss" scoped>
.merch-box {
  padding: 10px;
  width: 100%;
  .title {
    width: 100%;
    font-weight: bold;
    border-bottom: 1px dashed #ccc;
    padding-bottom: 15px;
    margin-bottom: 15px;
  }
  .title::before {
    width: 4px;
    height: 16px;
    background-color: #06b7ae;
    border-radius: 10px;
    display: inline-block;
    content: "";
    margin-right: 10px;
    margin-bottom: -2px;
  }
  .center-box {
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 10px;
    width: 100%;
    border-radius: 4px;
    display: flex;
    color: #666666;
    font-size: 14px;
    .passImg {
      display: flex;
      line-height: 40px;
      font-weight: bold;
      align-items: center;
      flex-direction: column;
      padding-bottom: 10px;
      img {
        width: 60px;
        height: 60px;
      }
    }
    .pass-text {
      width: 700px !important;
      text-align: left;
      display: flex;
      align-items: flex-end;
      font-size: 13px;
      .text-item {
        color: #00bfbf;
        cursor: pointer;
        font-weight: 700;
      }
    }
  }
}
.verifier-per {
  color: #666666;
  font-size: 14px;
  // width: 10%;
  margin: 20px;
  width: 400px;
  font-size: 14px;
  // height: 100px;
  line-height: 24px;
  div {
    width: 100%;
  }
}
</style>
